import React from "react";
import Navigation from "../Common/Navigation";
import { NavLink } from "react-router-dom";

var bnr = require("./../../images/background/bg-5.png");
const img1 = require("./../../images/background/about-01-min.jpg");

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: require("./../../images/logos/logo-mrtb.png").default,
    };
  }

  state = { isQuoteActive: false };

  handleQuoteToggle = () => {
    this.setState({ isQuoteActive: !this.state.isQuoteActive });
  };

  componentDidMount() {
    const handleScroll = () => {
      const offset = window.scrollY;

      const stickyheader = document.querySelector(".sticky-header ");

      if (stickyheader) {
        if (offset >= 100) {
          stickyheader.classList.add("is-fixed");
          stickyheader.classList.add("color-fill");
        } else {
          stickyheader.classList.remove("is-fixed");
          stickyheader.classList.remove("color-fill");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      this.setState({ logo: logopath });
    };
  }

  render() {
    const isQuoteActive = this.state.isQuoteActive;

    return (
      <>
        <header className="site-header header-style-1">
          <div className="top-bar bg-gray">
            <div className="container">
              <div className="row">
                <div className="mt-topbar-left clearfix">
                  <ul className="list-unstyled e-p-bx pull-right">
                    <li>
                      <i className="fa fa-envelope" /> mrtb@um6p.ma
                    </li>
                    <li>
                      <i className="fa fa-phone" />
                      +212 707 734 668
                    </li>
                  </ul>
                </div>

                <div
                  className="mt-topbar-right clearfix"
                  style={{ display: "flex" }}
                >
                  <div className="header-bar m-r20">
                    <ul className="social-icons  mt-social-links">
                      <li>
                        <a
                          href={"https://www.facebook.com/MRTBuilder.ma"}
                          target="_blank" rel="noreferrer"
                          className="fa fa-facebook"
                        />
                      </li>
                      <li>
                        <a
                          href={"https://www.instagram.com/mrtbuilder/?hl=en"}
                          target="_blank" rel="noreferrer"
                          className="fa fa-instagram"
                        />
                      </li>
                      <li>
                        <a
                          target="_blank" rel="noreferrer"
                          href={"https://twitter.com/MRTBuilder"}
                          className="fa fa-twitter"
                        />
                      </li>
                      <li>
                        <a
                          target="_blank" rel="noreferrer"
                          href={"https://www.linkedin.com/company/mrtbuilder/"}
                          className="fa fa-linkedin"
                        />
                      </li>
                      <li>
                        <a
                          target="_blank" rel="noreferrer"
                          href={
                            "https://www.youtube.com/channel/UCwdb86HiMX7N1LJ9_MaW5AA"
                          }
                          className="fa fa-youtube"
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="appint-btn">
                    <NavLink to={"#"} className="site-button">
                      English | Francais
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky-header main-bar-wraper">
            <div className="main-bar bg-white">
              <div className="container">
                <div className="logo-header">
                  <div className="logo-header-inner logo-header-one">
                    <a href={"/"}>
                      <img src={this.state.logo} alt="MRTB" />
                    </a>
                  </div>
                </div>
                {/* NAV Toggle Button */}
                <button
                  data-target=".header-nav"
                  data-toggle="collapse"
                  type="button"
                  className="navbar-toggle collapsed"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                {/* ETRA Nav */}
                <div className="extra-nav">
                  <div className="extra-cell">
                    <NavLink
                      to={"#"}
                      className="contact-slide-show text-primary"
                      onClick={this.handleQuoteToggle}
                    >
                      <i className="fa fa-hand-o-left arrow-animation"></i>
                    </NavLink>
                  </div>
                </div>
                {/* ETRA Nav */}
                <div
                  className="contact-slide-hide "
                  style={{
                    backgroundImage: "url(" + bnr.default + ")",
                    right: isQuoteActive ? "0px" : "-500px",
                  }}
                >
                  <div className="contact-nav">
                    <NavLink
                      to={"#"}
                      className="contact_close text-primary"
                      onClick={this.handleQuoteToggle}
                    >
                      ×
                    </NavLink>
                    <div className="contact-nav-form p-a30 p-t0">
                      {/* TAGS */}
                      <div className="">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="widget recent-posts-entry-date">
                              <h4 className="widget-title">
                                Recent Programmes
                              </h4>
                              <div className="widget-post-bx">
                                <div className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                  <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                                    <div className="mt-img-effect">
                                      <img src={img1.default} alt="" />
                                    </div>
                                  </div>
                                  <div className="mt-post-info">
                                    <div className="mt-post-header">
                                      <h6
                                        className="post-title"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Think
                                      </h6>
                                      <p
                                        className="m-b5"
                                        style={{ width: "300px" }}
                                      >
                                        <b>Think</b> est l’arme de collaboration
                                        du Moroccan retail Tech Builder ...
                                      </p>
                                    </div>
                                    <div className="mt-post-meta">
                                      <a
                                        href={"/programs/think"}
                                        title="Lire la suite"
                                        rel="bookmark"
                                        className="site-button-link"
                                      >
                                        Lire la suite
                                        <i className="fa fa-angle-right arrow-animation" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                  <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                                    <div className="mt-img-effect">
                                      <img src={img1.default} alt="" />
                                    </div>
                                  </div>
                                  <div className="mt-post-info">
                                    <div className="mt-post-header">
                                      <h6
                                        style={{ fontWeight: "bold" }}
                                        className="post-title"
                                      >
                                        Create
                                      </h6>
                                      <p
                                        className="m-b5"
                                        style={{ width: "300px" }}
                                      >
                                        Create est un programme qui stimule le
                                        développement de solutions digitales ...
                                      </p>
                                    </div>
                                    <div className="mt-post-meta">
                                      <a
                                        href={"/programs/create"}
                                        title="Lire la suite"
                                        rel="bookmark"
                                        className="site-button-link"
                                      >
                                        Lire la suite
                                        <i className="fa fa-angle-right arrow-animation" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10">
                                  <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                                    <div className="mt-img-effect">
                                      <img src={img1.default} alt="" />
                                    </div>
                                  </div>
                                  <div className="mt-post-info">
                                    <div className="mt-post-header">
                                      <h6
                                        className="post-title"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Start
                                      </h6>
                                      <p
                                        className="m-b5"
                                        style={{ width: "300px" }}
                                      >
                                        Start est un programme d’incubation
                                        permettant le passage du prototype ....
                                      </p>
                                    </div>
                                    <div className="mt-post-meta">
                                      <a
                                        href={"/programs/start"}
                                        title="Lire la suite"
                                        rel="bookmark"
                                        className="site-button-link"
                                      >
                                        Lire la suite
                                        <i className="fa fa-angle-right arrow-animation" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="full-social-bg">
                        <ul className="social-icons  mt-social-links">
                          <li>
                            <a
                              href={"https://www.facebook.com/MRTBuilder.ma"}
                              target="_blank" rel="noreferrer"
                              className="fa fa-facebook"
                            />
                          </li>
                          <li>
                            <a
                              href={
                                "https://www.instagram.com/mrtbuilder/?hl=en"
                              }
                              target="_blank" rel="noreferrer"
                              className="fa fa-instagram"
                            />
                          </li>
                          <li>
                            <a
                              target="_blank" rel="noreferrer"
                              href={"https://twitter.com/MRTBuilder"}
                              className="fa fa-twitter"
                            />
                          </li>
                          <li>
                            <a
                              target="_blank" rel="noreferrer"
                              href={
                                "https://www.linkedin.com/company/mrtbuilder/"
                              }
                              className="fa fa-linkedin"
                            />
                          </li>
                          <li>
                            <a
                              target="_blank" rel="noreferrer"
                              href={
                                "https://www.youtube.com/channel/UCwdb86HiMX7N1LJ9_MaW5AA"
                              }
                              className="fa fa-youtube"
                            />
                          </li>
                        </ul>
                      </div>
                      <div className="text-center">
                        <h6 className="font-weight-normal">
                          © 2021 MRTB. Tout droit réservé
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <Navigation />
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
