import React from "react";

var bnr1 = require("./../../images/offres/header2.jpg");

class Callus2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-tb80 m-b120 overlay-wraper bg-center bg-parallax bg-cover"
          data-stellar-background-ratio="0.5"
          style={{ backgroundImage: "url(" + bnr1.default + ")" }}
        >
          <div className="overlay-main bg-black opacity-07" />
          <div className="container">
            <div className="section-content">
              <div className="call-us-section text-center text-white">
                <h4 className="call-us-address m-t0 m-b20">
                  Prêt à faire le premier pas vers un avenir digitalisé ?
                  Dites-nous pourquoi votre entreprise est prête à changer
                  l'avenir du retail au Maroc.
                </h4>
                <a href="https://rrimuufk8pv.typeform.com/to/X5ksbBjm" target="_blank" rel="noreferrer" className="site-button btn-effect">
                  Postulez
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Callus2;
