import React from "react";

const services = [
  {
    count: 1,
    title: `Trouver votre programme`,
    icon: require("../../images/icon/program.png").default,
    description:
      "Les startups ne sont pas toutes pareilles, et nos programmes non plus. Consultez les différents programmes de MRTB et trouvez celui qui convient à votre startup et son stade de maturité.",
    btn: "Découvrir",
    link: "/programs",
  },
  {
    count: 2,
    title: "Postulez",
    icon: require("../../images/icon/postulez.png").default,
    description:
      "Prêt à faire le premier pas vers un avenir digitalisé ? Dites-nous pourquoi votre entreprise est prête à changer l'avenir du retail au Maroc. ",
    btn: "Postulez",
    link: "https://www.the-nextseed.com/fr/challenges/mrtb-challenges",
  },
  {
    count: 3,
    title: "Contactez-nous",
    icon: require("../../images/icon/contact.png").default,
    description:
      "Vous n'êtes pas encore prêt à postuler ? Ce n'est pas grave. Contactez-nous pour nous parler de vous et rester en contact.  ",
    btn: "Contactez-nous",
    link: "/contactus",
  },
];

var img1 = require("./../../images/background/bg-6.png");

class OurServices extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding  p-b50  square_shape2">
          <div className="section-content">
            <div
              className="Service-half-top p-tb80  bg-dark"
              style={{ backgroundImage: "url(" + img1.default + ")" }}
            >
              <div className="container">
                {/* TITLE START */}
                <div className="section-head text-white">
                  <div className="mt-separator-outer separator-left">
                    <div className="mt-separator">
                      <h2 className="text-white text-uppercase sep-line-one ">
                        <span className="font-weight-300 text-primary">
                          Pourquoi le
                        </span>{" "}
                        MRTB ?
                      </h2>
                    </div>
                  </div>
                  <h5>
                    Les startups ne sont pas toutes pareilles, et nos programmes
                    non plus. Consultez les différents programmes du MRTB et
                    trouvez celui qui convient à votre startup et à son stade de
                    maturité.
                  </h5>
                </div>
                {/* TITLE END */}
              </div>
            </div>
            <div className="services-half-bottom">
              <div className="container">
                <div className="row">
                  {services.map((item, index) => (
                    <div className="col-md-4 col-sm-6" key={index}>
                      <div className="mt-icon-box-wraper m-b30">
                        <div
                          style={{ height: "432px" }}
                          className="relative icon-count-2 bg-gray p-a30 p-tb50"
                        >
                          <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                            <span className="icon-cell">
                              <img src={item.icon} alt="" />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="m-b25">{item.title}</h4>
                            <p style={{ height: "125px" }}>
                              {item.description}
                            </p>
                            <a
                              href={item.link ? item.link : ""}
                              className="site-button-secondry btn-effect bg-dark"
                            >
                              {item.btn}
                              <i className="fa fa-angle-right arrow-animation" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurServices;
