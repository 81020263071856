import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

var bnrimg = require("./../../images/programs/header-min.jpg");
var img1 = require("../../images/programs/Think-min.jpg");

class ProjectDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Think"
            parent="Programmes"
            pagename="Think"
            bgimage={bnrimg.default}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              <div className="section-head">
                <div className="mt-separator-outer separator-left">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">
                        Think
                      </span>{" "}
                      {/* Offres */}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="project-detail-outer">
                {/* <h3 className="text-secondry">Create</h3> */}
                <h5 className="m-b30">
                  Think est l’arme de collaboration du Moroccan Retail Tech
                  Builder pour assurer un dialogue national et soutenir la
                  digitalisation de la chaine du commerce marocaine. Il est
                  fondé sur l’échange, le partage des connaissances et
                  l’exploitation des partenariats existants afin d’alimenter la
                  plateforme par des challenges à résoudre par l’ensemble des
                  talents sélectionnés dans les trois programmes piliers du MRTB
                  (create, start, scale)
                </h5>

                <div className="m-b30">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="product-block">
                        <div className="row">
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase text-secondry font-weight-600 m-b10">
                              Durée du programme :
                            </h4>
                            <p>
                              Le programme Think organise des différents formats
                              d’échange avec des durées modulables qui varient
                              entre une journée et 3 jours, dans les locaux de
                              StartGate, le campus de startup de l’UM6P à
                              Benguerir
                            </p>
                          </div>
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                              Après le programme :
                            </h4>
                            <p>
                              Après chaque format d’échange dans le cadre du
                              programme Think, les résultats de compilation de
                              l’activité en question alimentera les différents
                              programmes du MRTB avec la finalité de répondre
                              aux challenges soulevés de la part des partenaires
                              de la plateforme
                            </p>
                          </div>
                          {/* <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase  text-secondry font-weight-600 m-b10">
                              Période de candidature :
                            </h4>
                            <p>
                              Nous acceptons environ{" "}
                              <b>10 nouveaux idées de projets</b> deux fois par
                              an, avec des périodes de candidature en
                              janvier/février et août/septembre.
                            </p>
                          </div>
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                              Critères de candidature :
                            </h4>
                            <p>
                              Tout porteurs d’idées ayant un projet en phase de
                              démarrage basé sur le développement d’une solution
                              ayant pour but de digitaliser la chaine de valeur
                              du commerce local et l'ambition de consacrer plus
                              de temps et d'efforts au projet sont invités à
                              postuler.
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="mt-box">
                        <div className="mt-thum-bx  mt-img-effect yt-thum-box">
                          <img
                            src={img1.default}
                            alt=""
                            style={{
                              height: "430px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-detail-containt">
                  <div className="bg-white text-black">
                    <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                      Objectifs du programme :
                    </h4>
                    <p>
                      Rassemblant des adeptes dans le domaine, des innovateurs,
                      des leaders et des investisseurs dans une communauté
                      d'influence désireuse de participer à la transformation
                      digitale du commerce nationale, Think crée une dynamique
                      de génération de valeur à travers des startups en retail
                      au service du consommateur marocain, et cela à travers :
                      <ul className="p-l30">
                        <li>
                          L’organisation d’évènements (Table-ronde, salons,
                          sessions d’échange, hackathons, workshops, et
                          concours)
                        </li>
                        <li>
                          La <b>Mise en relation</b> des porteurs de projets
                          avec les partenaires de la plateforme et des
                          entreprises dans le retail
                        </li>
                        <li>
                          L’appui technique avec l’implication des partenaires
                          de la plateforme
                        </li>
                        <li>
                          <b>La Promotion de l’innovation</b> auprès des
                          donneurs d’ordre et facilitation des partenariats de
                          type Open Innovation
                        </li>
                        <li>
                          La création et fédération d’une communauté
                          d’entrepreneurs dans le retail en facilitant les
                          échanges et les collaborations et en créant des
                          réelles opportunités pour les porteurs de projets
                        </li>
                      </ul>
                    </p>
                    {/* <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                      Après le programme :
                    </h4>
                    <p>
                      Après chaque format d’échange dans le cadre du programme
                      Think, les résultats de compilation de l’activité en
                      question alimentera les différents programmes du MRTB avec
                      la finalité de répondre aux challenges soulevés de la part
                      des partenaires de la plateforme
                    </p> */}
                  </div>
                </div>
              </div>
              <div>
              <div>
                <a href="https://www.the-nextseed.com/fr/challenges/mrtb-challenges"
                  target="_blank" rel="noreferrer" className="site-button btn-effect m-tb30">
                REJOIGNEZ-NOUS !
                </a>
              </div>
              </div>
            </div>
          </div>

          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
