import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import OurPrograms from "./../Elements/OurPrograms";
import Callus2 from "../Elements/Callus2";
import ProgramPage from "../Elements/ProgramPage";

var bnrimg = require("./../../images/programs/header-min.jpg");

class Programs extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Programmes"
            pagename="Programmes"
            bgimage={bnrimg.default}
          />
          <ProgramPage />
          <OurPrograms />
          <Callus2 />
        </div>

        <Footer />
      </>
    );
  }
}

export default Programs;
