import React from "react";
import api from "../@api";

class controllers extends React.Component {
  getEventsList = (query) => {
    return new Promise((resolve, reject) => {
      api()
      .get("/events/find", {params: query})
      .then((response) => {
        // console.log({response})
          if (response.data.status === "success") {
            let data = response.data.result.Events;
            resolve(data);
          }
          if (response.data.status === "failure") {
            reject("Something is wrong try later or contact the administrator");
          }
        })
        .catch((err) => {
          console.log({err})
          reject("Something is wrong try later or contact the administrator");
        });
    });
  };

  getEventDetails = (eventId) => {
    return new Promise((resolve, reject) => {
      api()
        .get(`/events/${eventId}`)
        .then((response) => {
          if (response.data.status === "success") {
            let data = response.data.event;
            resolve(data);
          }
          if (response.data.status === "failure") {
            reject("Something is wrong try later or contact the administrator");
          }
        })
        .catch((err) => {
          reject("Something is wrong try later or contact the administrator");
        });
    });
  };
}

const instance = new controllers();

export default instance;
