import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

var bnrimg = require("./../../images/programs/header-min.jpg");
var img1 = require("../../images/programs/Create-min.jpg");

class ProjectDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Create"
            parent="Programmes"
            pagename="Create"
            bgimage={bnrimg.default}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              {/* TITLE START */}
              <div
                className="section-head"
                // style={{ marginBottom: "-30px" }}
              >
                <div className="mt-separator-outer separator-left">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">
                        Create
                      </span>{" "}
                      {/* Offres */}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="project-detail-outer">
                {/* <h3 className="text-secondry">Create</h3> */}
                <h5 className="m-b30">
                  <b>Create</b> est un programme qui accompagne des porteurs
                  d’idées à passer d’un concept d’une solution digitale
                  innovante à un prototype bien défini. Il offre aux porteurs de
                  projets sélectionnés un accompagnement spécialisé de trois
                  mois.
                </h5>

                <div className="m-b30">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="product-block">
                        <div className="row">
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase text-secondry font-weight-600 m-b10">
                              Durée du programme :
                            </h4>
                            <p>
                              Le programme <i>create</i> s'étale sur 3 mois. Le
                              programme se termine par l’organisation du « Demo
                              Day », où vous pouvez présenter le développement
                              de votre projet
                            </p>
                          </div>
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase  text-secondry font-weight-600 m-b10">
                              Période de candidature :
                            </h4>
                            <p>
                              Nous acceptons environ{" "}
                              <b>15 nouvelles idées de projets</b>, deux fois
                              par an, avec des périodes de candidature en
                              janvier/février et août/septembre.
                            </p>
                          </div>
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                              Critères de candidature :
                            </h4>
                            <p>
                              Tout porteurs d’idées ayant un projet en phase de
                              démarrage basé sur le développement d’une solution
                              ayant pour but de digitaliser la chaine de valeur
                              du commerce local et l'ambition de consacrer plus
                              de temps et d'efforts au projet sont invités à
                              postuler.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="mt-box">
                        <div className="mt-thum-bx  mt-img-effect yt-thum-box">
                          <img
                            src={img1.default}
                            alt=""
                            style={{
                              height: "430px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-detail-containt">
                  <div className="bg-white text-black">
                    <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                      Objectifs du programme :
                    </h4>
                    <p>
                      <ul className="p-l30">
                        <li>
                          Accompagner des concepts innovants qui vont
                          digitaliser la chaine de valeur du Retail au Maroc
                        </li>
                        <li>
                          Trouver des solutions digitales pour les challenges
                          partagés par les partenaires de la plateforme MRTB et
                          rechercher des clients.
                        </li>
                        <li>
                          Développer un Business Model Canvas viable en
                          vérifiant ou en faisant pivoter le modèle.
                        </li>
                        <li>Entrez en contact avec le premier client</li>
                      </ul>
                    </p>
                    <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                      Après le programme :
                    </h4>
                    <p>
                      Après le programme, vous aurez franchi de grandes étapes
                      dans le développement de votre idée projet. L'objectif
                      global du programme est de développer votre business model
                      Canvas, valider ses hypothèses, monter votre étude de
                      marché préliminaire et rencontrer votre premier client
                      potentiel. Vous aurez approfondi vos connaissances en
                      entrepreneuriat et en commercialisation et aurez bâti
                      votre propre réseau. Vous serez aussi éligible pour
                      postuler au deuxième programme du Moroccan Retail tech
                      Builder « Create » afin de développer les fonctionnalités
                      de votre premier produit ou service
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <a
                  href="https://www.the-nextseed.com/fr/challenges/mrtb-challenges"
                  target="_blank"
                  rel="noreferrer"
                  className="site-button btn-effect m-tb30"
                >
                  REJOIGNEZ-NOUS !
                </a>
              </div>
            </div>
          </div>

          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
