import React from "react";

const LoaderData = () => {
  return (
    <div className="loader-page">
      <div className="loading-cover" style={{ display: "block" }}>
        <div className="loading-box" />
        <div className="loading-pic">
          <div className="cssload-loader">Loading ...</div>
        </div>
      </div>
    </div>
  );
};

export default LoaderData;
