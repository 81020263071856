import React from "react";
import { NavLink } from "react-router-dom";

var bnr1 = require("./../../images/programs/header2-min.jpg");

class Callus2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full p-tb80 m-tb120 overlay-wraper bg-center bg-parallax bg-cover"
          data-stellar-background-ratio="0.5"
          style={{ backgroundImage: "url(" + bnr1.default + ")" }}
        >
          <div className="overlay-main bg-black opacity-07" />
          <div className="container">
            <div className="section-content">
              <div className="call-us-section text-center text-white">
                <h2 className="call-us-number m-b15 m-b0">
                  Le Retail Commence-ici
                </h2>
                <NavLink to="/contactus" className="site-button btn-effect">
                  Postulez
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Callus2;
