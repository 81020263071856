import React from "react";

var img1 = require("./../../images/background/bg-6.png");

class OurPrograms extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }
  render() {
    return (
      <>
        <div
          className="section-full p-t80 p-b80 bg-dark bg-repeat bg-moving square_shape2 inner-page-padding"
          style={{ backgroundImage: "url(" + img1.default + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator text-white">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">Nos</span>{" "}
                    Programmes
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content our-story">
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img
                        src={
                          require("./../../images/programs/Think-min.jpg").default
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div className="mt-box our-story-detail bg-moving bg-cover">
                    <h4 className="m-b20">Think</h4>
                    <p>
                      <b>Think</b> est l’arme de collaboration du Moroccan
                      Retail Tech Builder pour assurer un dialogue national et
                      soutenir la digitalisation de la chaine du commerce
                      marocaine. Il est fondé sur l’échange, le partage des
                      connaissances et l’exploitation des partenariats existants
                      afin d’alimenter la plateforme par des challenges à
                      résoudre par l’ensemble des talents sélectionnés dans les
                      trois programmes piliers du MRTB (create, start, scale)
                    </p>
                    <a
                      href={"/programs/think"}
                      className="site-button-link"
                      data-hover="Lire la suite"
                    >
                      Lire la suite{" "}
                      <i className="fa fa-angle-right arrow-animation" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img
                        src={
                          require("./../../images/programs/Create-min.jpg").default
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div className="mt-box our-story-detail bg-moving bg-cover">
                    <h4 className="m-b20">Create</h4>
                    <p>
                      <b>Create</b> est un programme qui accompagne des porteurs
                      d’idées à passer d’un concept d’une solution digitale
                      innovante à un prototype bien défini. Il offre aux
                      porteurs de projets sélectionnés un accompagnement
                      spécialisé de trois mois.
                    </p>
                    <a
                      href={"/programs/create"}
                      className="site-button-link"
                      data-hover="Lire la suite"
                    >
                      Lire la suite{" "}
                      <i className="fa fa-angle-right arrow-animation" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img
                        src={
                          require("./../../images/programs/Start-min.jpg").default
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div className="mt-box our-story-detail bg-moving bg-cover">
                    <h4 className="m-b20">Start</h4>
                    <p>
                      <b>Start</b> est un programme d’incubation permettant le
                      passage du prototype à la mise sur le marché à travers un
                      accompagnement qui va soutenir les porteurs d’idées de
                      projets à transformer leurs Business Model viable en
                      entreprise.
                    </p>
                    <a
                      href={"/programs/start"}
                      className="site-button-link"
                      data-hover="Lire la suite"
                    >
                      Lire la suite{" "}
                      <i className="fa fa-angle-right arrow-animation" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="our-story-pic-block">
                    <div className="mt-media our-story-pic">
                      <img
                        src={
                          require("./../../images/programs/Scale-min.jpg").default
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-sm-6">
                  <div className="mt-box our-story-detail bg-moving bg-cover">
                    <h4 className="m-b20">Scale</h4>
                    <p>
                      <b>Scale</b> est un programme conçu pour accélérer
                      rapidement la croissance des startups innovantes en
                      retail. Le programme vise à donner aux fondateurs les
                      outils nécessaires pour accélérer le développement de
                      leurs startups, leur déploiement commercial et augmenter
                      la valeur de leurs entreprises.
                    </p>
                    <a
                      href={"/programs/scale"}
                      className="site-button-link"
                      data-hover="Lire la suite"
                    >
                      Lire la suite{" "}
                      <i className="fa fa-angle-right arrow-animation" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OurPrograms;
