import React from "react";
import api from "../@api";

class controllers extends React.Component {
  submitContact = (data) => {
    return new Promise((resolve, reject) => {
      api()
        .post("/contacts/", data)
        .then((response) => {
          if (response.data.status === "success") {
            let data = response.data;
            resolve(data);
          }
          if (response.data.status === "failure") {
            reject("Something is wrong try later or contact the administrator");
          }
        })
        .catch((err) => {
          reject("Something is wrong try later or contact the administrator");
        });
    });
  };
}

const instance = new controllers();

export default instance;
