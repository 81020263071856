import React from "react";

var img1 = require("../../images/background/programs-01-min.jpg");

class ProgramPage extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-md-5 col-sm-6">
                  <div className="programs-section-full-v2">
                    <div
                      className="programs-section-full bg-no-repeat bg-cover overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + img1.default + ")" }}
                    >
                      <div className="overlay-main bg-black opacity-04" />

                      <div className="programs-section-inner" />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 col-sm-6">
                  {/* TITLE START */}
                  <div className="video-part-2 m-t70">
                    <h3>Digitaliser le Retail Marocain !</h3>
                    <h5>
                      Trouvez le bon programme d'accompagnement pour booster le
                      progrès de votre startup
                    </h5>
                    <p>
                      Le Moroccan Retail Tech Builder (MRTB) est la première
                      plateforme d’incubation et d’accélération de startups
                      digitales dans le secteur du commerce au Maroc, qui offre
                      un accompagnement de pointe pour les startups de toutes
                      tailles et stades de maturité.
                    </p>
                    <p>
                      Cet accompagnement se fera de la phase d’idéation et de
                      prototypage jusqu’à la phase de la mise sur le marché et
                      d’accélération à travers quatre programmes spécialisés
                      dans le retail et le digital. Ces programmes sont d'une
                      durée limitée qui aident des cohortes de startups dans le
                      processus de création de nouvelles entreprises. À travers
                      des ateliers de mentorat et de mise en relation avec des
                      investisseurs et des partenaires commerciaux.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProgramPage;
