import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home";
import Programs from "./Pages/Programs";
import News from "./Pages/News/News";
import NewsDetails from "./Pages/News/NewsDetailsSidebar";
import Error404 from "./Pages/Error/404";
import Error500 from "./Pages/Error/500";
import ContactUs from "./Pages/ContactUs";
import Offres from "./Pages/Offres";
import Expert from "./Pages/Applications/Expert";
import Startup from "./Pages/Applications/Startup";
import Create from "./Pages/Create";
import Think from "./Pages/Think";
import Start from "./Pages/Start";
import Scale from "./Pages/Scale";
import GA from "../@helpers/GoogleAnalytics/GoogleAnalytics";

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <div className="page-wraper">
          <Switch>
          {/* { GA.init() && <GA.RouteTracker /> } */}
            <Route path="/" exact component={Home} />

            <Route path="/programs" exact component={Programs} />
            <Route path="/programs/think" exact component={Think} />
            <Route path="/programs/create" exact component={Create} />
            <Route path="/programs/start" exact component={Start} />
            <Route path="/programs/scale" exact component={Scale} />

            <Route path="/news" exact component={News} />

            <Route path="/call-for-applications/expert" exact component={Expert} />
            <Route path="/call-for-applications/startup" exact component={Startup} />


            <Route path="/error/404" exact component={Error404} />
            <Route path="/error/500" exact component={Error500} />
            <Route path="/contactus" exact component={ContactUs} />
            <Route path="/offres" exact component={Offres} />
            <Route path="/news/:title" exact component={NewsDetails} />

            <Route component={Error404} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;
