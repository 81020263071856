import React, { useState, useEffect } from "react";
// import newsData from "../Pages/data/news";
import LoaderData from "./LoaderData";
import controllers from "../../controllers/events";
import moment from "moment";
import { useForm } from "react-hook-form";
import emailControllers from "../../controllers/newsletter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BeatLoader from "react-spinners/BeatLoader";

function NewsSidebar() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [emailLoading, setEmailLoading] = React.useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const CloseButton = ({ closeToast }) => {
    return (
      <i
        onClick={() => {
          closeToast();
        }}
        className="las la-times h-12  text-neutral-400"
      ></i>
    );
  };



  useEffect(() => {
    const notifySuccess = () => {
      return toast.success(showMessage.message, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
  
    const notifyError = () => {
      return toast.error(showMessage.message, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    showMessage && showMessage.variant === "success"
      ? notifySuccess()
      : notifyError();
  }, [showMessage]);

  useEffect(() => {
    const getEventsList = () => {
      return controllers
        .getEventsList({
          page: 0,
          limit: 2,
        })
        .then((response) => {
          setData(response);
          setTimeout(() => {
            setLoading(false);
          }, 100);
        })
        .catch((error) => {
          setLoading(false);
          window.location.href = "/error/500";
        });
    };
    getEventsList();
  }, []);

  const submitEmail = (data) => {
    emailControllers
      .submitEmail(data)
      .then((response) => {
        setEmailLoading(false);
        reset();
        setShowMessage({
          variant: "success",
          message: "Merci, Vous êtes maintenant abonné à notre newsletter !",
        });
      })
      .catch((error) => {
        setShowMessage({
          variant: "error",
          message:
            "Oups, une erreur s'est produits, Veuillez ressayer plus tard.",
        });
        setEmailLoading(false);
      });
  };

  function onSubmit(data) {
    if (!data.email) {
      setShowMessage({
        variant: "error",
        message: "Veuillez ajouter votre e-mail",
      });
      return;
    }
    setEmailLoading(true);
    submitEmail({
      email: data.email,
    });
  }

  return (
    <>
      <link rel="stylesheet" type="text/css" href="./assets/css/style.css" />
      <div className="col-lg-4 col-md-4">
        <ToastContainer closeButton={CloseButton} />
        <aside className="side-bar">
          {/* RECENT POSTS */}
          <div className="widget bg-white  recent-posts-entry">
            <h4 className="widget-title  ">Récents événements</h4>
            <div className="section-content">
              {loading ? (
                <div className="relative" style={{ padding: "50px" }}>
                  <LoaderData />
                </div>
              ) : (
                <div className="widget-post-bx">
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => (
                      <div key={index} className="widget-post clearfix">
                        <div className="mt-post-media">
                          <img
                            src={`${process.env.REACT_APP_DOMAIN}/api/eventsimages/${item.event_id}/${item.image}`}
                            alt=""
                          />
                        </div>
                        <div className="mt-post-info">
                          <div className="mt-post-meta" style={{ margin: "0" }}>
                            <ul>
                              <li className="post-author">
                                <i className="fa fa-history" />
                                <strong>
                                  {moment(item.date).format("DD MMM,YYYY")}
                                </strong>{" "}
                              </li>
                            </ul>
                          </div>
                          <div className="mt-post-header">
                            <h6 className="post-title">
                              <a
                                href={
                                  "/news/" +
                                  item.title.fr.split(" ").join("-") +
                                  "/?id=" +
                                  item.id
                                }
                              >
                                {item.title.fr
                                  .split(" ")
                                  .splice(0, 10)
                                  .join(" ")}
                                ...
                              </a>
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          {/* NEWSLETTER */}
          <div className="widget widget_newsletter-2 bg-white  ">
            <h4 className="widget-title  ">Newsletter</h4>
            <div className="newsletter-bx p-a30">
              <div className="newsletter-icon">
                <i className="fa fa-envelope-o" />
              </div>
              <div className="newsletter-content">
                <p>Soyez les premiers informés de nos actualités</p>
              </div>
              <div className="m-t20">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-group">
                    <input
                      className="form-control"
                      placeholder="ENTRER VOTRE EMAIL"
                      type="email"
                      required
                      {...register(
                        "email"
                        // { required: true },
                        // { maxLength: 100 }
                      )}
                    />
                    <span className="input-group-btn">
                      {!emailLoading ? (
                        <button
                          type="submit"
                          className="input-group-btn site-button"
                        >
                          <i
                            style={{ marginLeft: "-7px" }}
                            className="fa fa-paper-plane-o"
                          />
                        </button>
                      ) : (
                        <span className="input-group-btn site-button">
                          <BeatLoader
                            color="#fff"
                            loading={emailLoading}
                            size={5}
                          />
                        </span>
                      )}
                    </span>
                  </div>
                  <span className="text-white ml-2">
                    {errors.email && <p>Ajouter un email valide !!</p>}
                  </span>
                </form>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </>
  );
}

export default NewsSidebar;
