import React from "react";
import Header from "./../../Common/Header";
import Footer from "./../../Common/Footer";
import Banner from "./../../Elements/Banner";

var bnrimg = require("./../../../images/background/startup_header.jpg");

class ProjectDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Startup"
            parent="Appel à manifestation"
            pagename="Startup"
            bgimage={bnrimg.default}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head" style={{ marginBottom: "0" }}>
                <div className="mt-separator-outer separator-left ">
                  <div className="mt-separator" style={{ marginBottom: "0" }}>
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">
                        Startup
                      </span>{" "}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="project-detail-outer">
                <h3 className="text-secondry  font-italic font-semibold">
                  Appel à candidature pour des startups en retail
                </h3>
                <p className="m-t30">
                  Startups et porteurs de projets rejoignez-nous au premier
                  Venture Builder en Afrique dédié au secteur du commerce, le
                  MRTB !
                </p>
                <p>
                  Bénéficiez d’une expertise en accompagnement, du réseautage et
                  d’opportunités de croissance tout en étant hébergés au Hub
                  africain de l’Innovation et de l’Entrepreneuriat, StartGate !
                </p>
                <p className="">
                  Saisissez cet appel à candidature pour faire partie de la
                  prochaine promotion d’un de nos 4 programmes :
                  <a
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.the-nextseed.com/fr/challenges/mrtb-challenges"
                    // href="https://rrimuufk8pv.typeform.com/to/X5ksbBjm"
                  >
                    {" "}
                    lien d'inscription
                  </a>
                  , ou nous contacter par : mrtb@um6p.ma
                </p>
              </div>
              <div>
                <a
                  // href="https://rrimuufk8pv.typeform.com/to/X5ksbBjm"
                  href="https://www.the-nextseed.com/fr/challenges/mrtb-challenges"
                  target="_blank"
                  rel="noreferrer"
                  className="site-button btn-effect m-tb30"
                >
                  REJOIGNEZ-NOUS !
                </a>
              </div>
            </div>
          </div>

          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
