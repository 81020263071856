import React from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const header = require("./../../images/background/about-02-min.jpg");

var bnr1 = require("./../../images/background/bg-6.png");

class About extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding p-t80 p-b30  bg-repeat square_shape2 bg-moving"
          style={{ backgroundImage: "url(" + bnr1.default + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">
                      C’est quoi le{" "}
                    </span>{" "}
                    <span style={{ color: "#332a6d" }}>MRTB ?</span>
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="row">
                <div className="col-md-7 col-sm-12">
                  <div className="item">
                    <div className="mt-img-effect">
                      <img src={header.default} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 col-sm-12">
                  <div className="about-home-right bg-white p-a30">
                    <h3 className="m-t0" style={{ color: "#332a6d" }}>
                      C’est quoi le MRTB ?
                    </h3>
                    <p>
                      Le
                      <strong> Moroccan Retail Tech Builder (MRTB) </strong>
                      est la première plateforme d’incubation et d’accélération
                      de startups digitales dans le secteur du commerce au
                      Maroc.
                      <br />
                      C’est le fruit d’un partenariat entre le Ministère de
                      l’Industrie et du Commerce, l’Université mohammed vi
                      polytechnique et la Fondation OCP pour accélérer la
                      digitalisation du secteur du commerce, promouvoir
                      l’entrepreneuriat et l’émergence de champions nationaux à
                      forte valeur ajoutée.
                    </p>
                    <p>
                      Le <strong> MRTB </strong> a pour objectif d’accompagner
                      des porteurs de projets dans le développement de solutions
                      digitales innovantes au profit du secteur du commerce,
                      notamment le commerce de proximité qui a besoin d’outils
                      digitaux simples et accessibles lui permettant à la fois
                      de se moderniser et d’améliorer sa valeur ajoutée.
                      <br />
                      Cet accompagnement se fera de la phase d’idéation et de
                      prototypage jusqu’à la phase de la mise sur le marché et
                      d’accélération.
                    </p>
                    <p>
                      Comme startups innovantes, vous travaillerez avec des
                      experts, des dirigeants d’entreprises et des mentors
                      dévoués qui vous pousseront à aller plus loin. Vous aurez
                      même la chance de présenter votre startup lors
                      d'événements importants à la présence de clients et
                      investisseurs potentiels.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About;
