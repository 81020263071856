import React from "react";
import { NavLink } from "react-router-dom";

class Navigation extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }

  render() {
    return (
      <>
        <div
          className={
            this.props.bgcolor !== ""
              ? `header-nav navbar-collapse collapse ${this.props.bgcolor}`
              : "header-nav navbar-collapse collapse"
          }
        >
          <ul className="nav navbar-nav">
            <li>
              <a href={"/"}>Accueil</a>
            </li>
            <li>
              <a href={"/programs"}>Programmes</a>
            </li>
            <li>
              <a href={"/offres"}>Offres</a>
            </li>
            <li>
              <a href={"/news"}>Événements</a>
            </li>
            <li>
              <a>Appel à manifestation</a>
              <ul className="sub-menu">
                <li>
                  <a href={"/call-for-applications/expert"}>Expert</a>
                </li>
                <li>
                  <NavLink to={"/call-for-applications/startup"}>Startup</NavLink>
                </li>
              </ul>
            </li>

            <li>
              <a href={"/contactus"}>Contactez-nous</a>
            </li>
            <li>
              <div className="appint-btn" style={{ paddingLeft: "12px" }}>
                <NavLink to={"/#"} className="site-button">
                  English | Francais
                </NavLink>
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default Navigation;
