import React, { useEffect } from "react";
import moment from "moment";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import Banner from "../../Elements/Banner";
import controllers from "../../../controllers/events";
import LoaderData from "../../Elements/LoaderData";

var bnrimg = require("../../../images/news/header.jpg");

function News() {
  const [data, setData] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const getEventsList = () => {
      setLoading(true);
      return controllers
        .getEventsList()
        .then((response) => {
          setData(response);
          setTimeout(() => {
            setLoading(false);
          }, 400);
        })
        .catch((error) => {
          setLoading(false);
          window.location.href = "/error/500";
        });
    };
    getEventsList();
  }, []);

  if (loading) {
    return <LoaderData />;
  }
  return (
    <>
      <Header />
      <div className="page-content">
        <Banner
          title="Evénement"
          pagename="Evénement"
          bgimage={bnrimg.default}
          className="bg-event"
          // style={{ backgroundPostion : "0 -35rem" }}
        />

        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 p-b120 bg-white inner-page-padding">
          {/* NEWS CONTENT START */}
          <div className="container">
            <div className="news-grid clearfix row ">
              {data && data.length > 0 ? (
                data.map((item, index) => (
                  <div
                    className="masonry-item  col-lg-4 col-md-4 col-sm-6"
                    key={index}
                  >
                    <div className="blog-post blog-grid date-style-2">
                      <div className="mt-post-media mt-img-effect zoom-slow">
                        <img
                          style={{ height: "350px", objectFit: "cover" }}
                          src={`${process.env.REACT_APP_DOMAIN}/api/eventsimages/${item.event_id}/${item.image}`}
                          alt=""
                        />
                      </div>
                      <div className="mt-post-info p-t30">
                        <div className="mt-post-meta ">
                          <ul>
                            <li className="post-author">
                              <i className="fa fa-history" />
                              <strong>
                                {moment(item.date).format("DD MMM,YYYY")}
                              </strong>{" "}
                            </li>
                          </ul>
                        </div>
                        <div
                          // style={{ height: "40px" }}
                          className="mt-post-title "
                        >
                          <h4 className="post-title">
                            <a
                              href={
                                "/news/" +
                                item.title.fr.split(" ").join("-") +
                                "/?id=" +
                                item.id
                              }
                            >
                              {item.title.fr.split(" ").splice(0, 10).join(" ")}
                              ...
                            </a>
                          </h4>
                        </div>
                        <div className="mt-post-text">
                          {item.description.fr && (
                            <>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description.fr
                                    .split(" ")
                                    .splice(0, 20)
                                    .join(" "),
                                }}
                                className=""
                              ></p>
                              {/* <span>...</span> */}
                            </>
                          )}
                        </div>
                        <div className="clearfix">
                          <div className="mt-post-readmore pull-left">
                            <a
                              href={
                                "/news/" +
                                item.title.fr.split(" ").join("-") +
                                "/?id=" +
                                item.id
                              }
                              title="Lire la suite"
                              rel="bookmark"
                              className="site-button-link"
                            >
                              Lire la suite
                              <i className="fa fa-angle-right arrow-animation" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex flex-1 items-center justify-center h-full mx-auto">
                  <h4
                    color="textSecondary"
                    className="p-tb120 text-center"
                    variant="h5"
                  >
                    Aucun événement trouvé !
                  </h4>
                </div>
              )}
            </div>
            {/* <ul className="pagination m-b30 m-t50">
                <li>
                  <NavLink to={"#"}>«</NavLink>
                </li>
                <li>
                  <NavLink to={"#"}>1</NavLink>
                </li>
                <li>
                  <NavLink to={"#"}>2</NavLink>
                </li>
                <li>
                  <NavLink to={"#"}>3</NavLink>
                </li>
                <li>
                  <NavLink to={"#"}>4</NavLink>
                </li>
                <li>
                  <NavLink to={"#"}>5</NavLink>
                </li>
                <li>
                  <NavLink to={"#"}>»</NavLink>
                </li>
              </ul> */}
          </div>
          {/* NEWS CONTENT END */}
        </div>
        {/* SECTION CONTENT END  */}
      </div>

      <Footer />
    </>
  );
}

export default News;
