import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

var bnrimg = require("./../../images/programs/header-min.jpg");
var img1 = require("../../images/programs/Scale-min.jpg");

class ProjectDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Scale"
            parent="Programmes"
            pagename="Scale"
            bgimage={bnrimg.default}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              <div className="section-head">
                <div className="mt-separator-outer separator-left">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">
                        Scale
                      </span>{" "}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="project-detail-outer">
                {/* <h3 className="text-secondry">Scale</h3> */}
                <h5 className="m-b30">
                  <b>Scale</b> est un programme conçu pour accélérer rapidement
                  la croissance des startups innovantes en retail. Le programme
                  vise à donner aux fondateurs les outils nécessaires pour
                  accélérer le développement de leurs startups, leur déploiement
                  commercial et augmenter la valeur de leurs entreprises.
                  <br />
                  Cette phase est réservée aux startups au plus fort potentiel
                  et susceptibles d’intéresser les investisseurs en capital.
                </h5>

                <div className="m-b30">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="product-block">
                        <div className="row">
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase text-secondry font-weight-600 m-b10">
                              Durée du programme :
                            </h4>
                            <p>
                              3 mois, à partir de septembre/octobre. Le
                              programme se termine au Demo Day, où les startups
                              du programme Scale vont pitcher devant une
                              panoplie d’experts et d’investisseurs dans le
                              retail.
                            </p>
                          </div>
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase  text-secondry font-weight-600 m-b10">
                              Période de candidature :
                            </h4>
                            <p>
                              Nous acceptons environ
                              <b> 10 nouveaux projets une fois par an,</b>
                              avec des périodes de candidature en août/septembre
                            </p>
                          </div>
                          {/* <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                              Critères de candidature :
                            </h4>
                            <p>
                              <b>
                                Critère 0 : Avoir le Retail comme secteur
                                d’activit
                              </b>
                              <ul className="m-l20">
                                <li>
                                  Développer une innovation technologique avec
                                  but de digitaliser le secteur du retail
                                </li>
                                <li>
                                  Modèle économique validé après plusieurs
                                  pivots
                                </li>
                                <li>
                                  Processus opérationnel et commercial démontré
                                  et déployé
                                </li>
                                <li>Projections financières à 3 ans</li>
                                <li>
                                  Au moins 3 mois de trésorerie pour couvrir les
                                  charges courantes de la startup
                                </li>
                                <li>
                                  Ambition forte de l’équipe fondatrice qui
                                  nécessite une montée en puissance risquée
                                </li>
                              </ul>
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="mt-box">
                        <div className="mt-thum-bx  mt-img-effect yt-thum-box">
                          <img
                            src={img1.default}
                            alt=""
                            style={{
                              height: "430px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-detail-containt">
                  <div className="bg-white text-black">
                    <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                      Critères de candidature :
                    </h4>
                    <p>
                      {/* <b>Critère 0 : Avoir le Retail comme secteur d’activit</b> */}
                      <ul className="m-l20">
                        <li>
                          Développer une innovation technologique avec but de
                          digitaliser le secteur du retail
                        </li>
                        <li>Modèle économique validé après plusieurs pivots</li>
                        <li>
                          Processus opérationnel et commercial démontré et
                          déployé
                        </li>
                        <li>Projections financières à 3 ans</li>
                        <li>
                          Au moins 3 mois de trésorerie pour couvrir les charges
                          courantes de la startup
                        </li>
                        <li>
                          Ambition forte de l’équipe fondatrice qui nécessite
                          une montée en puissance risquée
                        </li>
                      </ul>
                    </p>
                    <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                      Objectifs du programme :
                    </h4>
                    <p>
                      <ul className="p-l30">
                        <li>
                          Maitriser le business plan avec ses différentes
                          composante
                        </li>
                        <li>
                          Catalyser la croissance opérationnelle de la startup
                          pour rendre le business model scalable
                        </li>
                        <li>
                          Développer une stratégie commerciale pour générer des
                          revenus récurrents et stables
                        </li>
                        <li>
                          Renforcer l’équipe de la startup en recrutant des
                          profils stratégiques et seniors
                        </li>
                        <li>
                          Maitriser les différentes méthodes de l’évaluation
                          d’une startu
                        </li>
                        <li>
                          Découvrir les différentes options d’investissements et
                          levées de fonds
                        </li>
                        <li>Concrétiser des levées de fond de série A</li>
                        <li>
                          Développer une stratégie communication forte pour
                          faire augmenter la notoriété de la startup
                        </li>
                        <li>
                          Pitcher auprès de divers investisseurs adaptés et
                          parties prenantes clés dans le Retail
                        </li>
                      </ul>
                    </p>
                    {/* <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                      Après le programme :
                    </h4>
                    <p>
                      Après le programme, vous aurez franchi de grandes étapes
                      dans le développement de votre idée projet. L'objectif
                      global du programme est de développer votre business model
                      Canvas, valider ses hypothèses, monter votre étude de
                      marché préliminaire et rencontrer votre premier client
                      potentiel. Vous aurez approfondi vos connaissances en
                      entrepreneuriat et en commercialisation et aurez bâti
                      votre propre réseau. Vous serez aussi éligible pour
                      postuler au deuxième programme du Moroccan Retail tech
                      Builder « Scale » afin de développer les fonctionnalités
                      de votre premier produit ou service
                    </p> */}
                  </div>
                </div>
              </div>
              <div>
              <div>
                <a href="https://www.the-nextseed.com/fr/challenges/mrtb-challenges"
                  target="_blank" rel="noreferrer" className="site-button btn-effect m-tb30">
                REJOIGNEZ-NOUS !
                </a>
              </div>
              </div>
            </div>
          </div>

          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
