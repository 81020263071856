import React, { useEffect, useState } from "react";
import LoaderData from "../../Elements/LoaderData";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import { useLocation } from "react-router-dom";
import NewsSidebar from "../../Elements/NewsSidebar";
import Banner from "../../Elements/Banner";
import controllers from "../../../controllers/events";

var bnrimg = require("../../../images/news/header.jpg");

function NewsDetails() {
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const eventId = useQuery().get("id");

  useEffect(() => {
    const getEventDetails = () => {
      setLoading(true);
      if (eventId && eventId != null) {
        return controllers
          .getEventDetails(eventId)
          .then((response) => {
            setData(response);
            setTimeout(() => {
              setLoading(false);
            }, 400);
          })
          .catch((error) => {
            setLoading(false);
            window.location.href = "/error/404";
          });
      } else {
        setLoading(false);
        window.location.href = "/error/404";
      }
    };
    getEventDetails();
  }, [eventId]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  if (loading) {
    return <LoaderData />;
  }
  return (
    <>
      <Header />
      <div className="page-content ">
        <Banner
          title={data && data.title.fr}
          pagename={data && data.title.fr}
          bgimage={bnrimg.default}
        />

        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 inner-page-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-8">
                <div className="blog-post date-style-3 blog-detail text-black">
                  <div className="mt-post-media clearfix m-b30">
                    <ul className="grid-post">
                      <li>
                        <div className="portfolio-item">
                          <img
                            className="img-responsive"
                            src={`${process.env.REACT_APP_DOMAIN}/api/eventsimages/${data.event_id}/${data.image}`}
                            alt=""
                            style={{
                              height: "400px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div className="mt-post-title m-t50">
                    <h3 className="post-title font-weight-600 text-center text-secondry">
                      {data && data.title.fr}
                    </h3>
                  </div>
                  <div className="mt-post-text m-t50 event-desc">
                    {data && data.description && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: data.description.fr,
                        }}
                        className=""
                      ></p>
                    )}
                  </div>
                </div>
              </div>
              <NewsSidebar />
            </div>
          </div>
        </div>
        {/* SECTION CONTENT END */}
      </div>

      <Footer />
    </>
  );
}

export default NewsDetails;
