import React from "react";
import OwlCarousel from "react-owl-carousel";
import { NavLink } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

var bnr1 = require("./../../images/background/bg6.jpg");

class Partners extends React.Component {
  render() {
    const options = {
      loop: true,
      margin: 0,
      duration: 200,
      autoplay: false,
      nav: false,
      dots: true,
      navText: [
        '<i className="fa fa-angle-left"></i>',
        '<i className="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        480: {
          items: 2,
        },
        767: {
          items: 3,
        },
        1000: {
          items: 3,
        },
      },
    };

    return (
      <>
        <div
          className="partners section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover"
          style={{ backgroundImage: "url(" + bnr1.default + ")" }}
        >
          <div className="container">
            <div className="section-content">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-center">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">Nos </span>
                      Partenaires
                    </h2>
                  </div>
                </div>
              </div>

              {/* TITLE END */}
              {/* Partners START */}
              <OwlCarousel
                className="owl-carousel home-client-carousel-2"
                {...options}
              >
                {/* {logos.map((item, index) => ( */}
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media ">
                      <NavLink to={"#"}>
                        <img
                          src={
                            require("../../images/partners/logo_ministere_MIC.png")
                              .default
                          }
                          alt=""
                          className="spec-logo"
                        />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <NavLink to={"#"}>
                        <img
                          style={{}}
                          src={
                            require("./../../images/partners/um6p-startgate.png")
                              .default
                          }
                          alt=""
                        />
                      </NavLink>
                    </div>
                  </div>
                </div>
                {/* <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <NavLink to={"#"}>
                        <img
                          src={
                            require("./../../images/partners/StartGate balck.png")
                              .default
                          }
                          alt=""
                        />
                      </NavLink>
                    </div>
                  </div>
                </div> */}
                <div className="item">
                  <div className="ow-client-logo">
                    <div className="client-logo client-logo-media">
                      <NavLink to={"#"}>
                        <img
                          src={
                            require("./../../images/partners/logo-ocp-fr.png")
                              .default
                          }
                          alt=""
                          className="spec-logo"
                        />
                      </NavLink>
                    </div>
                  </div>
                </div>
                {/* ))} */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Partners;
