import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import Callus3 from "../Elements/Callus3";

var bnrimg = require("./../../images/offres/header-min.jpg");

const offres = [
  {
    image: require("./../../images/offres/image-001-min.jpeg"),
    title: "Campus de StartGate",
    description:
      "Les startups de la plateforme MRTB auront accès à l’espace de travail, de créativité et d’échange de StartGate.",
    link: "https://www.startgate.ma/",
  },
  {
    image: require("./../../images/offres/image2-min.jpg"),
    title: "Mentorat par des experts",
    description:
      "Durant votre expérience avec MRTB, nous vous mettrons en relation avec des experts du secteur du retail, des leaders et des spécialistes dans des domaines tels que la création des entreprises, la gestion des produits et le digital.",
    filter: "cat-2",
    link: "/call-for-applications/expert",
  },
  {
    image: require("./../../images/offres/image3-min.jpg"),
    title: "Des ateliers pratiques et séminaires",
    description:
      "Des ateliers pratiques et séminaires sur le retail, la conception des produits, la digitalisation et son impact et plus. Une interaction unique et pratique avec des experts pour faire évoluer votre entreprise et relever ses principaux défis. ",
  },
  {
    image: require("./../../images/offres/image4-min.jpg"),
    title: "Un réseau d'opportunités",
    description:
      "Dans les différents programmes du MRTB, vous aurez l'occasion d'entrer en contact avec des investisseurs potentiels, régionaux et internationaux, dans des Événementss de StartGate et son écosystème, ce qui va vous permettre nouer des relations pour booster la croissance de votre entreprise.",
  },
];

class Offres extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: false,
      center: false,
      items: 3,
      margin: 40,
      nav: true,
      dots: false,
      navText: [
        '<i className="fa fa-angle-left"></i>',
        '<i className="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 2,
        },
        991: {
          items: 3,
        },
        1200: {
          items: 4,
        },
      },
    };

    return (
      <>
        <Header />
        <div className="page-content">
          <Banner title="Offres" pagename="Offres" bgimage={bnrimg.default} />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb50 inner-page-padding">
            <div className="container-fluid">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-left">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">Nos</span>{" "}
                      Offres
                    </h2>
                  </div>
                </div>
              </div>
              <div className="section-content offres">
                <div className="work-carousel-outer">
                  <OwlCarousel
                    className="owl-carousel work-carousel owl-btn-vertical-center"
                    {...options}
                  >
                    {offres.map((item, index) => (
                      <div key={index} className="item mt-box">
                        <div className="mt-img-effect mt-img-overlay6">
                          <img src={item.image.default} alt="" />
                        </div>
                        <div className="mt-info p-a30">
                          <h4 className="m-b20 m-t0">
                            {item.title}
                          </h4>
                          <p>{item.description}</p>
                          <a
                            href={item.link && item.link}
                            className="site-button btn-effect"
                          >
                            Lire la suite
                          </a>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END  */}
        </div>
        <Callus3 />
        <Footer />
      </>
    );
  }
}

export default Offres;
