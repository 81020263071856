import React from "react";
import Header from "./../../Common/Header";
import Footer from "./../../Common/Footer";
import Banner from "./../../Elements/Banner";

var bnrimg = require("./../../../images/offres/image2.jpg");

class ProjectDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Expert"
            parent="Appel à manifestation"
            pagename="Expert"
            bgimage={bnrimg.default}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              {/* TITLE START */}
              <div
                className="section-head"
                style={{ marginBottom: "0" }}
              >
                <div className="mt-separator-outer separator-left ">
                  <div className="mt-separator" style={{ marginBottom: "0" }}>
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">
                        Expert
                      </span>{" "}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="project-detail-outer">
                <h3 className="text-secondry  font-italic font-semibold">
                  Appel à manifestation pour des experts en accompagnement de
                  startups en retail{" "}
                </h3>
                <p className="m-b30">
                  Dans son approche de co-construction écosystémique du secteur
                  du commerce marocain, la plateforme « Moroccan Retail Tech
                  Builder » (MRTB) lancée conjointement par le Ministère de
                  l’Industrie, du Commerce et de l’Economie Verte et Numérique,
                  la Fondation OCP et l’Université Mohammed VI Polytechnique de
                  Benguerir (UM6P) lance son appel à manifestation pour la
                  sélection des experts{" "}
                  <b className="text-primary">
                    (entrepreneurs, consultants et mentors, …)
                  </b>{" "}
                  pour accompagner des startups nationales dans la
                  digitalisation du secteur du commerce.
                </p>

                <p className="m-b30">
                  Ce répertoire sera constitué d’experts et mentors disposant
                  d’une expertise (en entrepreneuriat, commerce, accompagnement
                  de startups, Gestion de projet, RH, IT, Développement
                  personnel, etc…) et de références par domaine d’intervention
                  conformément au dossier d’inscription.
                </p>
                <p className="m-b30">
                  Les experts désirant s’inscrire au répertoire de compétences
                  du MRTB sont invités à consulter le lien suivant :{" "}
                  <a
                    className="text-primary"
                    href="https://rrimuufk8pv.typeform.com/to/noCqETTO"
                  >
                    lien d'inscription
                  </a>
                  , ou nous contacter par : mrtb@um6p.ma
                </p>
              </div>
              <div>
                <a
                  href="https://rrimuufk8pv.typeform.com/to/noCqETTO"
                  className="site-button btn-effect m-tb30"
                >
                  Candidater
                </a>
              </div>
            </div>
          </div>

          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
