import React, {useEffect} from "react";
import Modal from "react-modal";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Slider from "./../Elements/Slider";
import About from "./../Elements/About";
import Section2 from "./../Elements/Section2";
import Partners from "./../Elements/Partners2";

// var pub2 = require("../../images/pubs/pub2.jpeg");
var pub3 = require("../../images/pubs/pub4.png");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    overflow: "hidden",
    border: "none",
    background: "transparent",
  },
};

function Home() {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 2000);
  }, []);


  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className="relative">
      <Header />
      <div className="page-content">
        <Slider />
        <About />
        <Section2 />
        <Partners />
      </div>
      <Footer />
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <div className="relative ">
          <a href="https://www.the-nextseed.com/en/challenges/mrtb-challenges-3rd" target="_blank" rel="noreferrer">
            <img
              style={{ width: "500px", cursor: "pointer" }}
              src={pub3.default}
              alt="pub"
            />
          </a>
          <i
            onClick={() => closeModal()}
            style={{
              cursor: "pointer",
              position: "absolute",
              fontSize: "30px",
              color: "#fff",
            }}
            className="fa fa-times arrow-animation"
          />
        </div>
      </Modal>
    </div>
  );
}

export default Home;
