import axios from 'axios';

const api = () => {
    const AUTH_TOKEN = window.localStorage.getItem('access_token');
    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_DOMAIN}/api/v1/mrtb`,
    });
    instance.defaults.headers.common.Authorization = AUTH_TOKEN;

    return instance
}

export default api