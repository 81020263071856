import React from "react";

class Error extends React.Component {
  render() {
    return (
      <>
        {/* <Header /> */}
        <div className="page-content">
          {/* SECTION CONTENTG START */}
          <div className="section-full p-tb150">
            <div className="container">
              <div className="section-content">
                <div className="page-notfound text-center">
                  <strong>Page Not Found</strong>
                  <span className="title">404</span>
                  <p>The webpage you are looking for is not here!</p>
                  <a
                    href="/"
                    title="Back to home"
                    className="site-button btn-effect"
                  >
                    Back to home
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        {/* <Footer /> */}
      </>
    );
  }
}

export default Error;
