import React from "react";

class Error500 extends React.Component {
  render() {
    return (
      <>
        {/* <Header /> */}
        <div className="page-content">
          {/* SECTION CONTENTG START */}
          <div className="section-full p-tb150">
            <div className="container">
              <div className="section-content">
                <div className="page-notfound text-center">
                  <strong>Something is wrong</strong>
                  <span className="title">500</span>
                  <p>
                    looks like we have an internal issue, please try again or
                    contact the support <br />
                    <b>mrtb@um6p.ma</b>
                  </p>
                  <a
                    href="/"
                    title="Back to home"
                    className="site-button btn-effect"
                  >
                    Back to home
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        {/* <Footer /> */}
      </>
    );
  }
}

export default Error500;
