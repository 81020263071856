import React, { useState, useEffect } from "react";
import controllers from "../../controllers/newsletter";
import newsControllers from "../../controllers/events";
import Switcher from "../Elements/Switcher";
import { useForm } from "react-hook-form";
import moment from "moment";
import LoaderData from "../Elements/LoaderData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BeatLoader from "react-spinners/BeatLoader";

var bgimage = require("./../../images/background/bg-site.png");
var logo = require("./../../images/logos/logo-mrtb.png");

function Footer() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [loading, setLoading] = React.useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [news, setNews] = React.useState(false);
  const [newsLoading, setNewsLoading] = React.useState(true);

  const CloseButton = ({ closeToast }) => {
    return (
      <i
        onClick={() => {
          closeToast();
        }}
        className="las la-times h-12  text-neutral-400"
      ></i>
    );
  };

  useEffect(() => {
    const notifySuccess = () => {
      return toast.success(showMessage.message, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };

    const notifyError = () => {
      return toast.error(showMessage.message, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    showMessage && showMessage.variant === "success"
      ? notifySuccess()
      : notifyError();
  }, [showMessage]);

  useEffect(() => {
    const getEventsList = () => {
      return newsControllers
        .getEventsList({
          page: 0,
          limit: 2,
        })
        .then((response) => {
          // console.log({response})
          setNews(response);
          setTimeout(() => {
            setNewsLoading(false);
          }, 100);
        })
        .catch((error) => {
          console.log({error})
          setNewsLoading(false);
          window.location.href = "/error/500";
        });
    };
    getEventsList();
  }, []);

  const submitEmail = (data) => {
    controllers
      .submitEmail(data)
      .then((response) => {
        setLoading(false);
        reset();
        setShowMessage({
          variant: "success",
          message: "Merci, Vous êtes maintenant abonné à notre newsletter !",
        });
      })
      .catch((error) => {
        setShowMessage({
          variant: "error",
          message:
            "Oups, une erreur s'est produits, Veuillez ressayer plus tard.",
        });
        setLoading(false);
      });
  };

  function onSubmit(data) {
    if (!data.email) {
      setShowMessage({
        variant: "error",
        message: "Veuillez ajouter votre e-mail",
      });
      return;
    }
    setLoading(true);
    submitEmail({
      email: data.email,
    });
  }

  return (
    <>
      <footer className="site-footer footer-large  footer-light	footer-wide">
        <ToastContainer closeButton={CloseButton} />
        <div
          className="container call-to-action-wrap bg-no-repeat bg-center"
          style={{ backgroundImage: "url(" + bgimage.default + ")" }}
        >
          <div className="p-a30  bg-dark">
            <div className="row">
              <div className="col-md-8 col-sm-8">
                <div className="call-to-action-left text-white">
                  <h4 className="text-uppercase m-b10 m-t0">
                    {/* Subscribe to our newsletter! */}
                    Inscription à la Newsletter !
                  </h4>
                  <span>Soyez les premiers informés de nos actualités</span>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="call-to-action-right">
                  <div className="widget_newsletter">
                    <div className="newsletter-bx">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-group">
                          <input
                            className="form-control"
                            placeholder="ENTRER VOTRE EMAIL"
                            type="email"
                            required
                            {...register(
                              "email"
                              // { required: true },
                              // { maxLength: 100 }
                            )}
                          />
                          <span className="input-group-btn">
                            {!loading ? (
                              <button
                                type="submit"
                                className="input-group-btn site-button"
                              >
                                <i
                                  style={{ marginLeft: "-7px" }}
                                  className="fa fa-paper-plane-o"
                                />
                              </button>
                            ) : (
                              <span className="input-group-btn site-button">
                                <BeatLoader
                                  color="#fff"
                                  loading={loading}
                                  size={5}
                                />
                              </span>
                            )}
                          </span>
                        </div>
                        <span className="text-white ml-2">
                          {errors.email && <p>Ajouter un email valide !!</p>}
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* FOOTER BLOCKES START */}
        <div className="footer-top overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              {/* ABOUT MRTB */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget widget_about">
                  <div className="logo-footer clearfix p-b15">
                    <a href={"/"}>
                      <img src={logo.default} alt="" />
                    </a>
                  </div>
                  <p className="max-w400">
                    Bénéficiez de l’écosystème d’innovation et d’expertise de
                    pointe du MRTB.
                  </p>
                  <ul className="social-icons  mt-social-links">
                    <li>
                      <a
                        href={"https://www.facebook.com/MRTBuilder.ma"}
                        target="_blank"
                        rel="noreferrer"
                        className="fa fa-facebook"
                      />
                    </li>
                    <li>
                      <a
                        href={"https://www.instagram.com/mrtbuilder/?hl=en"}
                        target="_blank"
                        rel="noreferrer"
                        className="fa fa-instagram"
                      />
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={"https://twitter.com/MRTBuilder"}
                        className="fa fa-twitter"
                      />
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={"https://www.linkedin.com/company/mrtbuilder/"}
                        className="fa fa-linkedin"
                      />
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={
                          "https://www.youtube.com/channel/UCwdb86HiMX7N1LJ9_MaW5AA"
                        }
                        className="fa fa-youtube"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              {/* RESENT POST */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget widget_address_outer">
                  <h4 className="widget-title">Contactez-nous</h4>
                  <ul className="widget_address">
                    <li>StartGate, Avenue Mohammed 6, en face de l’UM6P</li>
                    <li>mrtb@um6p.ma</li>
                    <li>+212 707 734 668</li>
                  </ul>
                </div>
              </div>
              {/* USEFUL LINKS */}
              <div className="col-lg-3 col-md-6 col-sm-6 footer-col-3">
                <div className="widget widget_services inline-links">
                  <h4 className="widget-title">pages</h4>
                  <ul>
                    <li>
                      <a href={"/programs"}>Programmes</a>
                    </li>
                    <li>
                      <a href={"/offres"}>Offres</a>
                    </li>
                    <li>
                      <a href={"/news"}>Événements</a>
                    </li>
                    <li>
                      <a href={"/contactus"}>Contactez-nous</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* TAGS */}
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="widget recent-posts-entry-date">
                  <h4 className="widget-title">Récents Événements</h4>
                  {newsLoading ? (
                    <div className="relative" style={{ padding: "50px" }}>
                      <LoaderData />
                    </div>
                  ) : (
                    <div className="widget-post-bx">
                      {news &&
                        news.length > 0 &&
                        news.map((item, index) => (
                          <div
                            key={index}
                            className="bdr-light-blue widget-post clearfix  bdr-b-1 m-b10 p-b10"
                          >
                            <div className="mt-post-date text-center text-uppercase text-white p-tb5">
                              <strong className="p-date">
                                {moment(item.date).format("DD")}
                              </strong>
                              <span className="p-month">
                                {moment(item.date).format("MMM")}
                              </span>
                              <span className="p-year">
                                {moment(item.date).format("YYYY")}
                              </span>
                            </div>
                            <div className="mt-post-info">
                              <div className="mt-post-header">
                                <h6 className="post-title">
                                  <a
                                    href={
                                      "/news/" +
                                      item.title.fr.split(" ").join("-") +
                                      "/?id=" +
                                      item.id
                                    }
                                  >
                                    {item.title.fr
                                      .split(" ")
                                      .splice(0, 5)
                                      .join(" ")}
                                    ...
                                  </a>
                                </h6>
                              </div>
                              <div className="mt-post-meta">
                                <a
                                  href={
                                    "/news/" +
                                    item.title.fr.split(" ").join("-") +
                                    "/?id=" +
                                    item.id
                                  }
                                  title="Lire la suite"
                                  rel="bookmark"
                                  className="site-button-link"
                                >
                                  Lire la suite
                                  <i className="fa fa-angle-right arrow-animation" />
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              {/* NEWSLETTER */}
            </div>
          </div>
        </div>
        {/* FOOTER COPYRIGHT */}
        <div className="footer-bottom overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              <div className="mt-footer-bot-center">
                <span className="copyrights-text">
                  © 2022 MRTB. Tout droit réservé.
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <Switcher />
    </>
  );
}

export default Footer;
