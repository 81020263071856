import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

var bnrimg = require("./../../images/programs/header-min.jpg");
var img1 = require("../../images/programs/Start-min.jpg");

class ProjectDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="Start"
            parent="Programmes"
            pagename="Start"
            bgimage={bnrimg.default}
          />

          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-left">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">
                        Start
                      </span>{" "}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="project-detail-outer">
                {/* <h3 className="text-secondry">Start</h3> */}
                <h5 className="m-b30">
                  <b>Start</b> est un programme d’incubation permettant le
                  passage du prototype à la mise sur le marché à travers un
                  accompagnement qui va soutenir les porteurs d’idées de projets
                  à transformer leurs Business Model viable en entreprise.
                  <br />
                  Le contenu de ce programme consiste à tester son produit sur
                  le marché et générer des premiers revenus, ainsi qu’à trouver
                  des premiers des premiers financements nécessaires à la
                  maturation de la startup. <br />
                  Le programme <b>Start</b> ne prend pas de participation au
                  capital de la startup, plus que cela, il facilite à travers
                  son écosystème la découverte client et l’accès aux premières
                  levées de fonds et aides publiques.
                </h5>

                <div className="m-b30">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="product-block">
                        <div className="row">
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase text-secondry font-weight-600 m-b10">
                              Durée du programme :
                            </h4>
                            <p>
                              6 mois, à partir de février/mars ou
                              novembre/décembre. Le programme se termine au Demo
                              Day, où les startups du programme <b>Start</b> vont
                              pitcher devant un jury d’experts et
                              d’investisseur.
                            </p>
                          </div>
                          <div className="col-md-12 m-b10">
                            <h4 className="text-uppercase  text-secondry font-weight-600 m-b10">
                              Période de candidature :
                            </h4>
                            <p>
                              Nous acceptons environ{" "}
                              <b>20 nouveaux projets une fois par an</b> , avec
                              des périodes de candidature en janvier/février et
                              septembre/octobre.
                            </p>
                          </div>
                          <div className="col-md-12 m-b10">
                            {/* <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                              Critères de candidature :
                            </h4> */}
                            {/* <p>
                              Tout porteurs d’idées ayant un projet en phase de
                              démarrage basé sur le développement d’une solution
                              ayant pour but de digitaliser la chaine de valeur
                              du commerce local et l'ambition de consacrer plus
                              de temps et d'efforts au projet sont invités à
                              postuler.
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="mt-box">
                        <div className="mt-thum-bx  mt-img-effect yt-thum-box">
                          <img
                            src={img1.default}
                            alt=""
                            style={{
                              height: "430px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-detail-containt">
                  <div className="bg-white text-black">
                    <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                      Objectifs du programme :
                    </h4>
                    <p>
                      <ul className="p-l30">
                        <li>
                          Analyser l’ensemble des composantes du modèle
                          économique de la startup afin que ce dernier soit
                          <b> performant et viable</b>
                        </li>
                        <li>
                          <b>
                            {" "}
                            Maitriser les principes de conception et prototypage
                          </b>
                        </li>
                        <li>
                          Définir les fonctionnalités primaires de son{" "}
                          <b>produit</b>
                        </li>
                        <li>
                          Créer un <b>MVP</b> (Minimum Viable Product) afin de
                          le tester le marché
                        </li>
                        <li>
                          Plonger profondément dans <b>l'analyse du marché</b>{" "}
                          et de la concurrence pour affiner la proposition de
                          valeur de la startup
                        </li>
                        <li>
                          Constituer <b>l’équipe de démarrage</b> de la startup
                          en cohérence avec le marché ciblé
                        </li>
                        <li>
                          Finaliser le <b>Business Plan</b> avec ses éléments
                          clés, voire la partie descriptive ou financière
                        </li>
                        <li>
                          Pitcher auprès de divers investisseurs et parties
                          prenantes clés dans le Retail
                        </li>
                      </ul>
                    </p>
                    <h4 className="text-uppercase text-secondry  font-weight-600 m-b10">
                      Après le programme :
                    </h4>
                    <p>
                      Plus facilement, le programme d'incubation Start aura
                      comme entrées un Business Model Canvas (BMC) viable et
                      quelques informations introductives de clients potentiels,
                      puis décomposera ses neuf blocs et développera un Modèle
                      économique (Business plan) viable pour atteindre un
                      prototype avec des fonctionnalité bien définies et une
                      maîtrise du marché en question.
                    </p>
                    <p>
                      Le business plan sera composé des modules suivants :
                      <ul className="m-l20">
                        <li>Résumé opérationnel</li>
                        <li>Description de l'entreprise,</li>
                        <li>Analyse de marché,</li>
                        <li>Organisation et gestion</li>
                        <li>Service ou ligne de Produits,</li>
                        <li>Projections financières,</li>
                        <li>Gestion des opérations,</li>
                        <li>Plan de marketing,</li>
                        <li>
                          Annexe (licences, propriété intellectuelle, et
                          documents juridiques…etc.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <a
                    href="https://www.the-nextseed.com/fr/challenges/mrtb-challenges"
                    target="_blank"
                    rel="noreferrer"
                    className="site-button btn-effect m-tb30"
                  >
                    REJOIGNEZ-NOUS !
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* SECTION CONTENT END  */}
        </div>

        <Footer />
      </>
    );
  }
}

export default ProjectDetail;
