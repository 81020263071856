import React, { useEffect, useState, useRef } from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import { useForm } from "react-hook-form";
import controllers from "../../controllers/contact";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";

var bnrimg = require("./../../images/background/contact-bg-min.jpg");

const override = css`
  display: block;
  margin: 0 auto;
`;

function ContactUs() {
  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const recaptchaRef = useRef();

  const CloseButton = ({ closeToast }) => {
    return (
      <i
        onClick={() => {
          closeToast();
        }}
        className="las la-times h-12  text-neutral-400"
      ></i>
    );
  };



  useEffect(() => {
    const notifySuccess = () => {
      return toast.success(showMessage.message, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
  
    const notifyError = () => {
      return toast.error(showMessage.message, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    showMessage && showMessage.variant === "success"
      ? notifySuccess()
      : notifyError();
  }, [showMessage]);


  const submitContact = (data) => {
    controllers
      .submitContact(data)
      .then((response) => {
        setLoading(false);
        reset();
        setCaptcha("");
        setShowMessage({
          variant: "success",
          message:
            "Merci. Votre message a bien été envoyé. Nous vous répondrons rapidement.",
        });
      })
      .catch((error) => {
        setShowMessage({
          variant: "error",
          message:
            "Oups, une erreur s'est produits, Veuillez ressayer plus tard.",
          // message: error,
        });
        setLoading(false);
      });
  };

  function onSubmit(data) {
    if (!captcha) {
      setShowMessage({
        variant: "error",
        message: "Veuillez vous assurer d'avoir entré un captcha valide",
      });
      return;
    }
    data.captcha = captcha;
    setLoading(true);
    submitContact(data);
  }

  function onChange(value) {
    setCaptcha(value);
  }

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner
          title="Contactez-nous"
          pagename="Contactez-nous"
          bgimage={bnrimg.default}
        />
        <ToastContainer closeButton={CloseButton} />;
        {/* SECTION CONTENTG START */}
        <div className="section-full p-tb80 inner-page-padding">
          {/* LOCATION BLOCK*/}
          <div className="container">
            {/* GOOGLE MAP & CONTACT FORM */}
            <div className="section-content">
              {/* CONTACT FORM*/}
              <div className="row">
                <div className="col-md-8 col-sm-6">
                  <form
                    className="contact-form cons-contact-form"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="contact-one m-b80">
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="mt-separator-outer separator-left">
                          <div className="mt-separator">
                            <h2 className="text-uppercase sep-line-one ">
                              <span className="font-weight-300 text-primary">
                                Contactez
                              </span>{" "}
                              nous
                              {/* In touch */}
                            </h2>
                            {/* <p>
                                Vous êtes entrepreneur, investisseur, stagiaire
                                potentiel, associé potentiel, salarié en
                                questionnement sur la création d’entreprise ?
                              </p> */}
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="form-group">
                        <input
                          name="fullname"
                          type="text"
                          required
                          className="form-control"
                          placeholder="Nom Complet*"
                          {...register(
                            "fullname",
                            { required: true },
                            { maxLength: 150 }
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="address"
                          type="text"
                          className="form-control"
                          placeholder="Adresse*"
                          required
                          {...register(
                            "address",
                            { required: true },
                            { maxLength: 500 }
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="email"
                          type="email"
                          className="form-control"
                          required
                          placeholder="Email*"
                          {...register(
                            "email",
                            { required: true },
                            { maxLength: 100 }
                          )}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="phone_number"
                          type="text"
                          className="form-control"
                          required
                          placeholder="Téléphone*"
                          {...register("phone_number", { required: true })}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows={4}
                          className="form-control "
                          required
                          placeholder="Message*"
                          {...register(
                            "message",
                            { required: true },
                            { maxLength: 2000 }
                          )}
                        />
                      </div>
                      <div
                        style={{
                          display: "initial",
                        }}
                        className="text-right"
                      >
                        {loading ? (
                          <BeatLoader
                            color="#342c6c"
                            loading={loading}
                            css={override}
                            size={15}
                          />
                        ) : (
                          <>
                            <ReCAPTCHA
                              ref={recaptchaRef}
                              sitekey="6LekKnYhAAAAAJ7RPrpz5-5PtDXr6gC2aNUPxuxu"
                              onChange={onChange}
                            />
                            <button
                              name="submit"
                              type="submit"
                              className="site-button btn-effect m-t20"
                            >
                              Envoyer
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="contact-info m-b30">
                    {/* TITLE START */}
                    <div className="section-head" style={{ marginBottom: "0" }}>
                      <div className="mt-separator-outer separator-left">
                        <div className="mt-separator">
                          <h2 className="text-uppercase sep-line-one ">
                            <span className="font-weight-300 text-primary">
                              Informations
                            </span>{" "}
                            de contact
                          </h2>
                        </div>
                      </div>
                    </div>
                    {/* TITLE END */}
                    <div className="bg-dark  p-a30 text-white">
                      <div className="mt-icon-box-wraper left p-b40">
                        <div className="icon-xs">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">
                            Numéro de téléphone
                          </h5>
                          <p>+212 707 734 668</p>
                        </div>
                      </div>
                      <div className="mt-icon-box-wraper left p-b40">
                        <div className="icon-xs">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">
                            Adresse e-mail
                          </h5>
                          <p>mrtb@um6p.ma</p>
                        </div>
                      </div>
                      <div className="mt-icon-box-wraper left">
                        <div className="icon-xs">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0 font-weight-500">Address</h5>
                          <p>StartGate, Avenue Mohammed 6, en face de l’UM6P</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gmap-outline">
              <div className="iframe">
                <iframe
                  style={{ border: 0, width: "100%", height: "350px" }}
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6750.8338059615335!2d-7.9338194!3d32.2199393!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdaf7bf618a1887b%3A0x3f463842fa3c3616!2steck%20park!5e0!3m2!1sen!2sma!4v1638729416464!5m2!1sen!2sma"
                  frameBorder="0"
                  allowFullScreen=""
                  title="adrress"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION CONTENT END */}
      </div>

      <Footer />
    </>
  );
}

export default ContactUs;
